import ApplicationLayout from "@/layouts/application";
import { ArrowCircleRight } from "@phosphor-icons/react";
import { CassiusLogoV2 } from "@/components/cassius_logo";
import { cn } from "@/lib/utils";
import { useIsMobile } from "@/lib/hooks/use_media_query";

export default () => {
  const isMobile = useIsMobile();
  const renderNoise = (className?: string) => {
    return (
      <div
        className={cn(
          "opacity-90 absolute pointer-events-none z-[99999] overflow-hidden w-full h-full",
          "before:content-[''] before:absolute before:inset-[-50%] before:w-[200%] before:h-[200%]",
          "before:bg-noise before:bg-[length:100px_100px]",
          "before:opacity-20 before:animate-noise before:will-change-auto",
          className
        )}
      ></div>
    );
  };

  return (
    <ApplicationLayout>
      <div
        className={cn(
          "min-h-screen flex flex-col items-center justify-center relative",
          isMobile && "justify-start"
        )}
      >
        <div
          className={cn(
            "text-[0.85rem] flex flex-col items-center tracking-wider px-4 text-[#EEEDE7]",
            isMobile && "text-base px-10 pt-16"
          )}
        >
          <div className='flex flex-col items-center'>
            <a href='https://tally.so/r/wgLEGl' className='w-fit'>
              <CassiusLogoV2
                className='text-[#E26500] hover:scale-125 transition-all'
                width={isMobile ? 70 : 60}
              />
            </a>
            <div
              className={cn(
                "leading-loose pt-12 text-center font-normal",
                isMobile && "pt-20"
              )}
            >
              <div>We don’t sell motivation.</div>
              <div>
                We’re building tools{" "}
                <span className='italic'>— for the disciplined.</span>
              </div>
              <div className='pt-10'>Train.</div>
              <div className=''>Manage.</div>
              <div className=''>Improve.</div>
            </div>
          </div>
          <a
            href='https://tally.so/r/wgLEGl'
            className={cn(
              "mt-10  flex items-center text-[#989797] hover:scale-105 transition-all  tracking-wider font-black  py-1 rounded-full",
              isMobile && "fixed bottom-10"
            )}
          >
            <ArrowCircleRight
              className='hover:scale-105'
              size={isMobile ? 38 : 28}
              weight='fill'
            />
          </a>
        </div>

        {renderNoise()}
      </div>
    </ApplicationLayout>
  );
};
